import { Settings as SettingsIcon } from '@material-ui/icons';

import List from './SettingsList';
import Edit from './SettingsEdit';

export default {
    list: List,
    edit: Edit,
    icon: SettingsIcon,
};
