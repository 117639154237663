import { TranslationMessages } from 'ra-core';
import frenchMessages from 'ra-language-french';

const customFrenchMessages: TranslationMessages = {
    ...frenchMessages,
    global: {
        auth: {
            email: 'Email',
            sign_in: 'Entrer',
            password: 'Mot de Passe',
            sign_in_error: "Erreur d'Authentification Failed",
        },
    },
    pos: {
        search: 'Rechercher',
        configuration: 'Configuration',
        language: 'Langue',
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
            nbActivties: 'Nombre d’activités',
            nbUsers: 'Nombre d’utilisateurs',
            month_history: "Chiffre d'affaire sur 30 jours",
            new_orders: 'Nouvelles commandes',
            pending_reviews: 'Commentaires à modérer',
            new_customers: 'Nouveaux clients',
            pending_orders: 'Commandes à traiter',
            order: {
                items: 'par %{customer_name}, un produit |||| par %{customer_name}, %{nb_items} produits',
            },
            welcome: {
                title: 'Bienvenue dans EZZAL',
                subtitle: 'Ceci est le back-office EZZAL..',
                aor_button: 'Site web de EZZAL',
                demo_button: 'Code source de cette démo',
            },
        },
        menu: {
            sales: 'Ventes',
            prducts: 'Produits',
            users: 'Utilisateurs',
            delivery: 'Livraisons',
        },
        actions: {
            concel: 'Annuler',
        },
        deleteMessage: {
            ok: 'Élément supprimé avec succès',
            ko: 'Élément non supprimé',
        },
    },
    resources: {
        users: {
            name: 'Client |||| Clients',
            fields: {
                name: {
                    first: 'Nom',
                    last: 'Prénom',
                    full: 'Nom Complet',
                },
                email: 'Email',
                phone: 'Tél',
                civility: 'Genre',
                uid: 'Identifiant',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                hasRequestAccountDelete: 'Suppression de Compte',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                nb_courses: 'N de Commandes',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        raport: {
            name: 'Rapport |||| Rapports',
            fields: {
                type: 'Type',
                by: 'Par',
                created_at: 'Date',
                raport: 'Contenu',
            },
            types: {
                report: 'Rapport',
                lieudefinitivementFerme: 'Lieu définitivement fermé',
                horairesOuverture: "Horaires d'ouverture",
                adresseoupositionsurlacarte: 'Adresse ou position sur la carte',
                nomdulieu: 'Nom du lieu',
                contacts: 'Contacts (téléphone ou site web)',
                photos: 'Photos',
                error: 'Erreur',
                other: 'Autre',
            },
        },
        rating: {
            name: 'Avis |||| Avis',
            fields: {
                by: 'Par',
                rating: 'Note',
                comment: 'Commentaire',
                created_at: 'Date',
            },
            delete: {
                title: 'Confirmer la suppression',
                content:
                    'Êtes-vous sûr de vouloir supprimer cet avis ? Cette action est irréversible.',
                cancel: 'Annuler',
                confirm: 'Confirmer',
            },
            actions: {
                delete: 'Supprimer',
            },
        },
        admin_messages: {
            name: 'Message |||| Messages',
            fields: {
                type: 'Type',
                message: 'Message',
                heading: 'Titre',
                is_sent: 'Est envoyé',
                last_sent_at: 'Dernier Envoie',
                slide: 'Slide',
                is_published: 'Publié',
                target: 'Cible',
                users: 'Clients',
                created_at: 'Date de Création',
            },
            types: {
                standard: 'Normal',
                slide: 'Slide',
            },
            targets: {
                all: 'Tout le monde',
                users: 'Clients spécifiques',
            },
            actions: {
                send: 'Diffuser',
                resend: 'Rediffuser',
            },
        },
        settings: {
            name: 'Paramètres |||| Paramètres',
            fields: {
                storeVersion: "Version de l'app",
                storeURL: "URL de l'app",
                forceUpgrade: 'Forcer une mise à jour',
                maintenanceMode: 'Activer le mode maintenance',
                created_at: 'Date de creation',
                updated_at: 'Dernière mise à jour',
            },
            groups: {
                androidApp: 'APP ANDROID',
                iosApp: 'APP IOS',
            },
            tabs: {
                versions: 'GESTION DES VERSIONS',
            },
        },
        activities: {
            name: 'Activité |||| Activités',
            fields: {
                name: {
                    first: 'Nom',
                    last: 'Prénom',
                    full: 'Nom Complet',
                },
                email: 'Email',
                uid: 'Identifiant',
                details: 'Details',
                baseInfo: 'Information basique',
                reservationLink: 'Lien de réservation',
                city: 'Ville',
                phone: 'Tél',
                logo: 'Logo (55px x 55px carre)',
                hasReviews: 'A des avis',
                logoList: 'Logo',
                clickContact: 'Cliquez pour contacter',
                category: 'Categorie',
                subCategory: 'Sous Categorie',
                activityCollection: 'Collection',
                tags: 'Tags',
                description: 'Description',
                hasComplaints: 'A des rapports',
                longitude: 'Longitude',
                latitude: 'Latitude',
                address: 'Adresse',
                from: 'De',
                to: 'A',
                hour: 'Heure',
                minute: 'Minute',
                days: 'Jours',
                day: 'Jour',
                civility: 'Genre',
                openingTime: 'Horaires',
                openingHour: 'Ouverture heure',
                openingTimeAndTags: 'Horaires et Tags',
                images: 'Images (430px x 485px)',
                imagesList: 'Images',
                openingMinute: 'Ouverture minute',
                closingHour: 'Fermeture heure',
                workingTime: 'Horaires',
                closingMinute: 'Fermeture minute',
                isOpen: 'Ouvert',
                website: 'Site Web',
                genre: 'Genre',
                address1: 'Adresse',
                isEnabled: 'Activé',
                title: 'Titre',
                country: 'Pays',
                birthdate: 'date de Naissance',
                socialReason: 'Raison Sociale',
                created_at: "Date d'inscription",
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                nb_courses: 'N de Commandes',
            },
            civilities: {
                monsieur: 'M',
                madame: 'Mme',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                quizzes: 'Quizzes',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        slides: {
            name: 'Slide |||| Slides',
            fields: {
                name: 'Nom',
                image: 'Image (345 x 145)',
                imageList: 'Image',
                description: 'Description',
                is_enabled: 'Activée',
                created_at: 'Date de Création',
            },
        },
        collections: {
            name: 'Collection |||| Collections',
            fields: {
                name: 'Nom',
                image: 'Image',
                is_enabled: 'Activée',
                description: 'Description',
                created_at: 'Date de Création',
            },
        },
        cities: {
            name: 'Ville |||| Villes',
            fields: {
                name: 'Nom',
                imageSelected: 'image sélectionnée ',
                image: 'Image',
                is_enabled: 'Activée',
                description: 'Description',
                created_at: 'Date de Création',
            },
        },
        tags: {
            name: 'Tag |||| Tags',
            fields: {
                name: 'Nom',
                description: 'Description',
                imageSelected: 'image sélectionnée ',
                image: 'Image',
                is_enabled: 'Activée',
                created_at: 'Date de Création',
            },
        },
        categories: {
            name: 'Catégorie |||| Catégories',
            fields: {
                name: 'Nom',
                image: 'Image',
                description: 'Description',
                is_enabled: 'Activée',
                parent: 'Parent',
                created_at: 'Date de Création',
            },
        },
        admins: {
            name: 'Administrateur |||| Administrateurs',
            fields: {
                name: {
                    first: 'Nom',
                    last: 'Prénom',
                    full: 'Nom Complet',
                },
                email: 'Email',
                phone: 'Tél',
                address1: 'Adresse',
                city: 'Ville',
                country: 'Pays',
                socialReason: 'Raison Sociale',
                created_at: 'Crée le',
                updated_at: 'Dernière mise à jour',
                account_enabled: 'Activé',
                commands: 'Commandes',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
            },
            filters: {
                last_visited: 'Dernière visite',
                today: "Aujourd'hui",
                this_week: 'Cette semaine',
                last_week: 'La semaine dernière',
                this_month: 'Ce mois-ci',
                last_month: 'Le mois dernier',
                earlier: 'Plus tôt',
                has_ordered: 'A déjà commandé',
                has_newsletter: 'Abonné newsletter',
                group: 'Segment',
            },
            fieldGroups: {
                identity: 'Identité',
                phone: 'Tél',
                address: 'Adresse',
                pricing: 'Tarification',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            page: {
                delete: 'Supprimer le client',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
    },
};

export default customFrenchMessages;
