import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, MenuItemLink, DashboardMenuItem } from 'react-admin';

import users from '../users';
import admins from '../admins';
import collections from '../collections';
import categories from '../categories';
import slides from '../slides';
import settings from '../settings';
import tags from '../tags';
import cities from '../cities';
import activities from '../activities';
import admin_messages from '../admin_messages';
import { AppState } from '../types';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuUsers' | 'menuDelivery';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    useSelector((state: AppState) => state.theme); // force rerender on theme change

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />

            <MenuItemLink
                to={`/activities`}
                primaryText={translate(`resources.activities.name`, {
                    smart_count: 2,
                })}
                leftIcon={<activities.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/collections`}
                primaryText={translate(`resources.collections.name`, {
                    smart_count: 2,
                })}
                leftIcon={<collections.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/categories`}
                primaryText={translate(`resources.categories.name`, {
                    smart_count: 2,
                })}
                leftIcon={<categories.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/cities`}
                primaryText={translate(`resources.cities.name`, {
                    smart_count: 2,
                })}
                leftIcon={<cities.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/slides`}
                primaryText={translate(`resources.slides.name`, {
                    smart_count: 2,
                })}
                leftIcon={<slides.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/tags`}
                primaryText={translate(`resources.tags.name`, {
                    smart_count: 2,
                })}
                leftIcon={<tags.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/users`}
                primaryText={translate(`resources.users.name`, {
                    smart_count: 2,
                })}
                leftIcon={<users.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/admins`}
                primaryText={translate(`resources.admins.name`, {
                    smart_count: 2,
                })}
                leftIcon={<admins.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/admin_messages`}
                primaryText={translate(`resources.admin_messages.name`, {
                    smart_count: 2,
                })}
                leftIcon={<admin_messages.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/settings`}
                primaryText={translate(`resources.settings.name`, {
                    smart_count: 2,
                })}
                leftIcon={<settings.icon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />

            

            {isXSmall && logout}
        </div>
    );
};

export default Menu;
