import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslate } from 'react-admin';
import { ListActionsProps } from 'react-admin';
import { Datagrid, DateField, List } from 'react-admin';

const useStyles = makeStyles(theme => ({
    list: {
        margin: '10px 20px',
    },
    toolbar: {
        height: 0,
        minHeight: 0,
    },
    actionsBox: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        width: '100%'
    }
}));

const EmptyToolbar = () => null;

const SettingsListActions = () => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Box className={classes.actionsBox}>
            <Typography variant="h5" component="h2">
                {translate('resources.settings.name', { smart_count: 2 })}
            </Typography>
        </Box>
    );
};

const SlideList = (props: any) => {
    const classes = useStyles();

    return (
        <List
            {...props}
            className={classes.list}
            filters={false}
            sort={{ field: 'title', order: 'ASC' }}
            perPage={10}
            exporter={false}
            bulkActionButtons={false}
            actions={<SettingsListActions />}
            toolbar={<EmptyToolbar />}
        >
            <Datagrid 
                optimized 
                rowClick="edit"
                bulkActionButtons={false}
                headerButtons={[]}
            >
                <DateField
                    source="created_at"
                    type="date"
                    showTime
                    label="resources.settings.fields.created_at"
                />
                <DateField
                    source="updated_at"
                    type="date"
                    showTime
                    label="resources.settings.fields.updated_at"
                />
            </Datagrid>
        </List>
    );
};

export default SlideList;
