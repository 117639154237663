import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    BooleanInput,
    FormWithRedirect,
    SaveButton,
    Toolbar,
} from 'react-admin';
import { Box, Typography, Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: '#f5f5f5',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: theme.spacing(4),
    },
    headerTitle: {
      color: theme.palette.primary.main,
      fontSize: '24px',
      fontWeight: 700,
      marginBottom: theme.spacing(4),
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      paddingBottom: theme.spacing(2),
    },
    sectionTitle: {
      fontSize: '20px',
      color: theme.palette.text.primary,
      fontWeight: 600,
      marginBottom: theme.spacing(3),
    },
    inputLabel: {
      fontSize: '16px',
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(1),
    },
    textInput: {
      marginBottom: theme.spacing(3),
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        backgroundColor: '#ffffff',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      },
    },
    booleanInput: {
      marginBottom: theme.spacing(2),
      '& .MuiTypography-root': {
        fontSize: '16px',
      },
      '& .MuiSwitch-root': {
        marginRight: theme.spacing(1),
      },
    },
    toolbar: {
      marginTop: theme.spacing(4),
      padding: 0,
      backgroundColor: 'transparent',
      '& .MuiButton-contained': {
        borderRadius: '8px',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        fontSize: '16px',
        fontWeight: 600,
      },
    },
  })
);

const EditToolbar: FC<any> = (props) => {
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <SaveButton />
    </Toolbar>
  );
};

const VersionEdit: FC = (props: any) => {
  const classes = useStyles();

  return (
    <Edit title={null} undoable={false} {...props}>
      <FormWithRedirect
        {...props}
        render={formProps => (
          <Card className={classes.card}>
            <form>
              <CardContent>
                <Typography variant="h4" className={classes.headerTitle}>
                  GESTION DES VERSIONS
                </Typography>

                <Grid container spacing={4}>
                  {/* iOS Section */}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" className={classes.sectionTitle}>
                      APP IOS
                    </Typography>
                    
                    <Box mb={3}>
                      <Typography className={classes.inputLabel}>
                        Version de l'app
                      </Typography>
                      <TextInput
                        source="ios.storeVersion"
                        label=""
                        fullWidth
                        variant="outlined"
                        className={classes.textInput}
                      />
                    </Box>

                    <Box mb={3}>
                      <Typography className={classes.inputLabel}>
                        URL de l'app
                      </Typography>
                      <TextInput
                        source="ios.storeURL"
                        label=""
                        fullWidth
                        variant="outlined"
                        className={classes.textInput}
                      />
                    </Box>

                    <Box>
                      <BooleanInput
                        source="ios.forceUpgrade"
                        label="Forcer une mise à jour"
                        className={classes.booleanInput}
                      />
                      <BooleanInput
                        source="ios.maintenanceMode"
                        label="Activer le mode maintenance"
                        className={classes.booleanInput}
                      />
                    </Box>
                  </Grid>

                  {/* Android Section */}
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" className={classes.sectionTitle}>
                      APP ANDROID
                    </Typography>
                    
                    <Box mb={3}>
                      <Typography className={classes.inputLabel}>
                        Version de l'app
                      </Typography>
                      <TextInput
                        source="android.storeVersion"
                        label=""
                        fullWidth
                        variant="outlined"
                        className={classes.textInput}
                      />
                    </Box>

                    <Box mb={3}>
                      <Typography className={classes.inputLabel}>
                        URL de l'app
                      </Typography>
                      <TextInput
                        source="android.storeURL"
                        label=""
                        fullWidth
                        variant="outlined"
                        className={classes.textInput}
                      />
                    </Box>

                    <Box>
                      <BooleanInput
                        source="android.forceUpgrade"
                        label="Forcer une mise à jour"
                        className={classes.booleanInput}
                      />
                      <BooleanInput
                        source="android.maintenanceMode"
                        label="Activer le mode maintenance"
                        className={classes.booleanInput}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              <EditToolbar {...formProps} />
            </form>
          </Card>
        )}
      />
    </Edit>
  );
};

export default VersionEdit;

